import React from 'react'
import { Outlet, NavLink } from 'react-router-dom'
import { Box, Tab, Tabs } from '@mui/material'
import { useTerminal } from 'hooks/terminals'

const TerminalsLayout = () => {
  const { tabValue, tabs } = useTerminal()

  return (
    <>
      <Box
        sx={{
          width: '100%',
          backgroundColor: '#ffffff',
          borderRadius: '10px',
          overflow: 'hidden',
          flexGrow: { xs: '0', sm: '1' },
        }}
      >
        <Box
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
            maxWidth: { xs: 'calc(100vw - 40px)', sm: '100%' },
            width: '100%',
          }}
        >
          <Tabs value={tabValue} variant='scrollable'>
            {tabs.map((tab) => {
              return (
                <Tab
                  key={tab.path}
                  label={tab.label}
                  component={NavLink}
                  to={tab.path}
                  value={tab.path}
                />
              )
            })}
          </Tabs>
        </Box>
        <Outlet />
      </Box>
    </>
  )
}

export default TerminalsLayout
