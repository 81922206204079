import React, { FC } from 'react'
import { Box, Table as MUITable, TableBody, TableContainer } from '@mui/material'
import { TableRow } from 'components/terminal/Cells'
import { TableHead, Toolbar, TablePagination } from 'components/generic'
import { CellModal, ConfirmationModal, CopyTerminalModal } from 'components/modals'
import { useCells } from 'hooks/cells'
import { RowData } from 'types/Table'
import { TerminalFooter } from 'components/terminal'
import { useTerminal } from 'hooks/terminals'

const Cells: FC = () => {
  const {
    id,
    methods,
    onSubmit,
    address,
    confirmModal,
    copyTerminalModal,
    handleDelete,
    handleConfirmModalClose,
    handleConfirmModalOpen,
    handleCloseTerminal,
    handleCopyTerminalModalOpen,
    handleCopyTerminalModalClose,
  } = useTerminal()
  const {
    order,
    orderBy,
    page,
    rowsPerPage,
    handleRequestSort,
    handleChangePage,
    handleChangeRowsPerPage,
    rows,
    count,
    cellsIsLoading,
    filteredAddedProductList,
    headCells,
    cellModal,
    handleCellModalOpen,
    handleCellModalClose,
    productList,
    rowsPerPageOptions,
    labelDisplayedRows,
  } = useCells()

  return (
    <>
      <Box
        sx={{
          position: 'relative',
          height: '90%',
          width: '100%',
          backgroundColor: '#ffffff',
          borderRadius: '10px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <Toolbar
          title={address}
          buttonLabel='+ Добавить товар'
          handleModalOpen={handleCellModalOpen}
          isLoadingData={cellsIsLoading}
        />
        <TableContainer
          sx={{
            height: { xs: 'auto', sm: 'calc(100vh - 320px)' },
          }}
        >
          <MUITable stickyHeader size='small'>
            <TableHead
              headCells={headCells}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {rows.map((row: RowData) => (
                <TableRow
                  key={row.id}
                  id={row.id}
                  code={row.code}
                  cookingTime={row.cooking_time}
                  productId={row.product}
                  price={row.price}
                  productList={productList}
                  title={address}
                  containers={row.containers}
                  quantityNotification={row.quantity_notification}
                  needQuantityNotification={row.need_quantity_notification}
                  isComposite={row.is_composite}
                  placement={row.placement}
                  quantity={row.quantity}
                />
              ))}
            </TableBody>
          </MUITable>
        </TableContainer>
        <TerminalFooter
          handleConfirmModalOpen={handleConfirmModalOpen}
          handleCloseTerminal={handleCloseTerminal}
          methods={methods}
          onSubmit={onSubmit}
          handleCopyTerminalModalOpen={handleCopyTerminalModalOpen}
        >
          <TablePagination
            rowsPerPageOptions={rowsPerPageOptions}
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelDisplayedRows={labelDisplayedRows}
          />
        </TerminalFooter>
      </Box>
      <CellModal
        isOpen={cellModal}
        onClose={handleCellModalClose}
        modalType='create'
        productList={filteredAddedProductList}
      />
      <ConfirmationModal
        isOpen={confirmModal}
        onClose={handleConfirmModalClose}
        confirm={handleDelete}
      />
      <CopyTerminalModal
        isOpen={copyTerminalModal}
        onClose={handleCopyTerminalModalClose}
        terminalId={id}
      />
    </>
  )
}

export default Cells
